import i18n from "@/i18n";
export default [
  {
    path: "operations",
    name: "operations",
    component: () =>
      import(
        /* webpackChunkName: "admin-operations" */ "@/views/admin/Operations.vue"
      ),
    meta: {
      title: i18n.tc("operation.title", 2),
      auth: true,
    },
  },
  {
    path: "operations/:id/report",
    name: "operations-report",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "admin-operations" */ "@/views/admin/OperationsReport.vue"
      ),
    meta: {
      title: i18n.tc("operation.title", 2),
      auth: true,
    },
  },
  {
    path: "clients",
    name: "clients",
    component: () =>
      import(
        "@/views/admin/Clients.vue"
      ),
    meta: {
      title: i18n.tc("controller.title", 2),
      auth: true,
    },
  }
];
