import _axios from "axios";
import store from "../store";
export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    // mode: "no-cors",
    Accept: "application/json",
  },
});

export default () => {
  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers["Authorization"] = "Token " + store.state.auth.token;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/authtoken/", params),
    },
    operations: {
      getSummaryTable: (id, params) =>axios.get(`/areas/${id}/summary/`,{params}),
      getAreas:() => axios.get(`/areas/?location__id=1`),
      getItems: (params)  => axios.get(`/items/`,{params}),
      getItemStock: (id)  => axios.get(`/items/${id}/itemstock/`),

    },
    clients: {
      getClients: (params) => axios.get("/associateds/", { params }),
      addClients: (params) => axios.post("/associateds/", params),
      updateClients: (params, id) => axios.put(`/associateds/${id}/`, params),
      deleteClients: (id) => axios.delete(`/associateds/${id}/`),
    },
    users: {
      getUsers: (limit, offset) =>
      axios.get(`/users/?limit=${limit}&offset=${offset}`),
      getUsersByFilter: (query ,limit, offset) => axios.get(`/users/${query}&limit=${limit}&offset=${offset}`),
      addUser: (params) => axios.post("/users/", params),
      updateUser: (params, id) => axios.put(`/users/${id}/`, params),
      deleteUser: (id) => axios.delete(`/users/${id}/`),
    },
  };
};
