import Vue from "vue";
import { formatDate } from "@/utils";

const state = {
  printers: [],
};

const mutations = {
  SET_PRINTER_TABLE: (state, payload) => {
    state.printers = payload;
  },
};

const actions = {
  fetchPrinters({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.dashboard
        .getPrinterTable(params)
        .then(({ data }) => {
          commit("SET_PRINTER_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getPrinters: (state) =>
    state.printers.map((x) => ({
      ...x,
      formattedDate: formatDate(x.creation_date),
    })),
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
