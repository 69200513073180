import Vue from "vue";
// import { formatDate } from "@/utils";

const state = {
  clients: [],
};

const mutations = {
  SET_CLIENTS_TABLE: (state, payload) => {
    state.clients = payload;
  },
};

const actions = {
  fetchClientsTable({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.clients
        .getClients(params)
        .then(({ data }) => {
          commit("SET_CLIENTS_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  addClients(store, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.clients
        .addClients(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  updateClients(store, { params, id }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.clients
        .updateClients(params, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  deleteClients(store, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.clients
        .deleteClients(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getClientsTable: (state) => state.clients,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
