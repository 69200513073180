import Vue from "vue";

const state = {
  areas: [],
  summary: [],
  item: [],
  itemstock: []

};

const mutations = {
  SET_AREAS: (state, payload) => {
    state.areas = payload;
  },
  SET_SUMMARY: (state, payload) => {
    state.summary = payload;
  },
  SET_ITEM: (state, payload) => {
    state.item = payload;
  },
  SET_ITEMSTOCK: (state, payload) => {
    state.itemstock = payload;
  },
};

const actions = {
  fetchArea({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.operations
        .getAreas()
        .then(async ({ data }) => {
          commit("SET_AREAS", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchSummaryTable({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.operations
        .getSummaryTable(parameters.id, parameters.params)
        .then(({ data }) => {
          commit("SET_SUMMARY", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchItem({ commit },parameters) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.operations
        .getItems(parameters)
        .then(async ({ data }) => {
          commit("SET_ITEM", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchItemStock({ commit },item) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.operations
        .getItemStock(item.id)
        .then(async ({ data }) => {
          commit("SET_ITEMSTOCK", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getAreas: (state) => state.areas,
  getSummary: (state) => state.summary,
  getItem: (state) => state.item,
  getItemStock: (state) => state.itemstock,


};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
